// File: main.js
import {pageTransition, delay, contentAnimation, aboutPageAnimations, navEnter } from './transitions';
import {initDropdown, defaultStorage} from './dropdown';
import { initScrollAnimationsDna, initScrollAnimationsHome} from './scrollAnimations';
import barba from '@barba/core';
import Alpine from 'alpinejs'
import { initSwiper } from './swipers';;


window.Alpine = Alpine
 
Alpine.start()

function getAnchor() {
  return (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
}
function pageScroll(x) {
  
  setTimeout(function () {
    $('html, body').animate({
          scrollTop: $('#'+x).offset().top
      }, 1000);
  }, 1000);
  
}
barba.init({
  sync: true,
  transitions: [
    {
      name: 'about',
      to: {namespace: ['team','events']},
      
      async leave(data) {
        const done = this.async();
        pageTransition();
        await delay (600);
        done();
      },
      async enter(data) {
        // contentAnimation();initScrollAnimationsAbout
        aboutPageAnimations();
        navEnter();
        initSwiper();
        defaultStorage();
      },
      async once(data) {
        // contentAnimation();
        aboutPageAnimations();
        initSwiper()
      }
    },
    {
      name: 'home',
      to: {namespace: 'home'},

      async leave(data) {
        const done = this.async();
        pageTransition();
        await delay (600);
        done();
      },
      async enter(data) {
        navEnter();
        contentAnimation();
        // initScrollAnimationsHome();
        initSwiper();
        defaultStorage();
      },
      async afterEnter(data) {
        var achor = getAnchor();
        if (achor != null) {
          document.getElementById(achor).scrollIntoView();
        }
      },
      async once(data) {
        contentAnimation();
        initScrollAnimationsHome();
        initSwiper();
      }
    },
    {
      name: 'about',
      to: {namespace: ['cases']},

      async leave(data) {
        const done = this.async();
        pageTransition();
        await delay (600);
        done();
      },
    
      async enter(data) {
        // contentAnimation();initScrollAnimationsAbout
        aboutPageAnimations();
        navEnter();
        initSwiper();
        initDropdown();
      },
      async once(data) {
        // contentAnimation();
        aboutPageAnimations();
        initSwiper()
      }
    },
    {
      name: 'dna',
      to: {namespace: ['dna']},

      async leave(data) {
        const done = this.async();
        pageTransition();
        await delay (600);
        done();
      },
      async enter(data) {
        navEnter();
        contentAnimation();
        initScrollAnimationsDna();
        defaultStorage();
      },
      async once(data) {
        contentAnimation();
        initScrollAnimationsDna();
      }
    },
    {
      name: 'blog',
      to: {namespace: ['blog']},

      async leave(data) {
        const done = this.async();
        pageTransition();
        await delay (600);
        done();
      },
      async enter(data) {
        navEnter();
      },
      async afterEnter(data) {
        window.location.reload();
      }
    },
    {
      name: 'standard',
      to: {namespace: ['career', 'privacy']},
      
      async leave(data) {
        const done = this.async();
        pageTransition();
        await delay (600);
        done();
      },
      async enter(data) {
        navEnter();
        contentAnimation();
        initScrollAnimationsHome();
        initSwiper();
        defaultStorage();
      },
      async once(data) {
        contentAnimation();
        initScrollAnimationsHome();
        initSwiper();
      }
    }
  ]});
// https://codepen.io/mulegoat/project/editor/XvJVNP
// https://greensock.com/forums/topic/25460-barba-scrolltrigger-janky-timeline-after-transition/
barba.hooks.leave(() => {
  window.scrollTo(0, 0);
});

// barba.hooks.beforeEnter(() => {
//   scrollAnimationRefresh();
// });

barba.hooks.beforeEnter(({ current, next }) => {
  
  var beforeEnterPromiseAll = new Promise(function (resolve) {
    
    scrollAnimationKill();
    
    resolve();
    
  });

  return beforeEnterPromiseAll;

});


barba.hooks.enter(({ current, next }) => {   
  
  var enterPromiseAll = new Promise(function (resolve) {
    
    current.container.remove();		
    
    resolve();
    
  });

  return enterPromiseAll;

});

// barba.hooks.afterEnter(({ current, next }) => {
  
  
//   var afterEnterPromiseAll = new Promise(function (resolve) {
    
//     scrollAnimationRefresh();
    
//     resolve();
    
//   });

//   return afterEnterPromiseAll;

// });

import Swiper from 'swiper/bundle';
  // import styles bundle
import 'swiper/css/bundle';


export function initSwiper(){
    const swiper = new Swiper('.swiper-container', {
    loop: true,
    slidesPerView: 1,
    spaceBetween: 1,
    autoplay: {
        delay: 3000,
    },
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
    breakpoints: {
        640: {
        slidesPerView: 1.5,
        },
        1024: {
        slidesPerView: 3.5,
        },
    },
    });

    const swiper2 = new Swiper('.swiper-containerx', {
        loop: false,
        slidesPerView: 1,
        spaceBetween: 32,
        navigation: {
          nextEl: '.next-buttonx',
          prevEl: '.prev-buttonx',
        },
        breakpoints: {
          640: {
            slidesPerView: 1.5,
            centeredSlides: true,
          },
          1024: {
            centeredSlides: false,
            slidesPerView: 1.5,
          },
          2000: {
            slidesPerView: 2.5,
            centeredSlides: false
          }
        },
      })



    const gallerySliderSwiper = new Swiper('.swiper-containery', {
      loop: true,
      slidesPerView: 1,
      spaceBetween: 16,
      // autoplay: {
      //   delay: 5000,
      // },
      navigation: {
        nextEl: '.next-buttony',
        prevEl: '.prev-buttony',
      },
    })
      }
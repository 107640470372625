import { marks } from "@sanity/block-content-to-markdown/lib/serializers";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);


var triggerParams = require('./params.json');

// function for the scroll trigger
export function initScrollAnimationsHome() {

  const tl_home = gsap.timeline({
    scrollTrigger: {
      "trigger": ".trig-home",
      "start": "top+=20 bottom",
      "end": "+=350",
      "each": "power2",
      "scrub": 1,
      // "markers": true
    }
  });
  tl_home.from(".home-move1", { y: -100, autoAlpha: 0, duration: 1 });
  tl_home.from(".home-move2", { y: 50, autoAlpha: 0, duration: 1 }, "<");

  var appears = gsap.utils.toArray('.appear');

  appears.forEach((el) => {

    gsap.from(el, {
      autoAlpha: 0,
      scrollTrigger: {
        "trigger": el,
        "start": "top-=250 center",
        "each": "power2",
        // markers: true

      },
      autoAlpha: 0,
      y: 30,
      duration: 1
    })
  });


  // gsap.from(".appear1", { autoAlpha: 0,
  //   scrollTrigger: {
  //     "trigger": ".appear1",
  //     "start": "top-=200 bottom",
  //     "each": "power2",
  //     // markers: true

  //   },
  // autoAlpha:0, 
  // y:30, 
  // duration:0.5
  // });

  gsap.from(".home-move4", {
    scrollTrigger: {
      "trigger": ".home-move4",
      "start": "top-=200 center",
      "end": "+=400",
      "each": "power2",
      // markers: true
    },
    stagger: 0.3,
    y: 20,
    autoAlpha: 0,
    duration: 1
  });

  gsap.from(".home-move6", {
    scrollTrigger: {
      "trigger": ".home-move6",
      "start": "top-=450 center",
      "end": "+=300",
      // "scrub": 1,
      // markers: true,
      ease: "power2"
    },
    stagger: 0.3,
    autoAlpha: 0,
    x: 400,
    duration: 1
  })
}

export function initScrollAnimationsDna() {
  gsap.from(".dna-move1", {
    scrollTrigger: {
      "trigger": ".dna-move1",
      "start": "top-=300 center",
      id: "dna-move1",
      ease: "power2",
      "end": "bottom center",
      "each": "power4"
    },
    autoAlpha: 0,
    translateX: -100,
    duration: 1
  })

  gsap.from(".dna-move2", {
    scrollTrigger: {
      "trigger": ".dna-move2",
      "start": "top-=300 center",
      id: "dna-move2",
      ease: "power2",
      "end": "bottom center"
    },
    autoAlpha: 0,
    translateX: 100,
    duration: 1
  })

  gsap.from(".dna-move3", {
    scrollTrigger: {
      "trigger": ".dna-move3",
      "start": "top-=300 center",
      id: "dna-move3",
      ease: "power2",
      "end": "bottom center"
    },
    autoAlpha: 0,
    translateX: -100,
    duration: 1
  })

}

// function for the scroll trigger refresh
export function scrollAnimationKill() {
  let triggers = ScrollTrigger.getAll();
  triggers.forEach(trigger => {
    trigger.kill();
  });
};

export function scrollAnimationRefresh() {
  ScrollTrigger.refresh();
}
import { gsap } from "gsap";
//https://www.annaehrnsperger.de/11ty-barb


export function delay(time) {
  time = time || 1000;
  return new Promise(done => {
    setTimeout(done, time);
  });
}

export function aboutPageAnimations() {

  const tl_about = gsap.timeline();
  // tl_about.from(".about-move1", {x: 500, autoAlpha: 0, duration: 1, "ease": "power2"});
  // tl_about.from(".about-page-title", { y: -2, autoAlpha: 0, duration: 0.5, ease: "power2" }, "-=0.5");
  // tl_about.from(".about-move3", {x:-350, autoAlpha: 0, duration:1, ease: "power2.out"});
}

export function navEnter() {
  var tl_nav = gsap.timeline();
  // tl_nav.from('nav', { autoAlpha:0, y:-300, duration: 1});
}

export function pageTransition() {
  var tl = gsap.timeline();
  // tl.to('nav', { y:-300, duration: 0.5});
  tl.to('section', { autoAlpha: 0, translateY: -300, rotation: 0.001, duration: 0.4 });
  var element = document.getElementById("menu");
  var elementBtn = document.getElementById("menuBtn");
  elementBtn.classList.remove("open");
  element.classList.add("hidden");
}

export function contentAnimation() {
  var tl = gsap.timeline();
  // tl.from('.once', { duration: 0.5, autoAlpha: 0, translateY: 150});
  tl.from('section', { duration: 0.5, autoAlpha: 0, translateY: 150, delay: 0.5 });
  tl.to('.once', { duration: 1.5, autoAlpha: 1, translateY: -50 }, "<");
  tl.to('img.move', { duration: 0.7, translateY: -20, clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)", autoAlpha: 1 }, "-=1.1");
  // tl.from('.move2', {stagger:0.1, autoAlpha:0, translateY: 50}, "-=0.5");
}

export function initDropdown() {
    var selectElement = document.getElementById('categories');

    if (selectElement != null) {
        selectElement.addEventListener('change', function () {
            var selectedValue = selectElement.value;
            if (selectedValue == 'showall') {
                localStorage.setItem("selectedValue", selectedValue);
                showAllElements();
            } else {
                localStorage.setItem("selectedValue", selectedValue);
                updateDisplayedElements(selectedValue);
            }
        });
    }

    window.addEventListener("load", loadDropdownValue());

    function showAllElements() {
        var elements = document.getElementsByClassName('hidecase');
        for (var i = 0; i < elements.length; i++) {
            elements[i].style.display = 'block';
        }
    }

    function updateDisplayedElements(selectedValue) {
        var elements = document.getElementsByClassName('hidecase');
        for (var i = 0; i < elements.length; i++) {
            var element = elements[i];
            if (element.classList.contains(selectedValue)) {
                element.style.display = 'block';
            } else {
                element.style.display = 'none';
            }
        }
    }

    function loadDropdownValue() {
        if (typeof (Storage) !== "undefined") {
            var localStorager = localStorage.getItem("selectedValue")
            if (localStorager == 'showall') {
                selectElement.value = localStorager;
                showAllElements();
            }
            if (localStorager !== 'showall') {
                selectElement.value = localStorager;
                updateDisplayedElements(localStorager);
            }
        }
    }
}

export function defaultStorage() {
    localStorage.setItem("selectedValue", 'showall');
}
